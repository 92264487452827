import { render, staticRenderFns } from "./VoteHistoryModal.vue?vue&type=template&id=b5c20122&scoped=true"
import script from "./VoteHistoryModal.vue?vue&type=script&lang=js"
export * from "./VoteHistoryModal.vue?vue&type=script&lang=js"
import style0 from "./VoteHistoryModal.vue?vue&type=style&index=0&id=b5c20122&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5c20122",
  null
  
)

export default component.exports