var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.voteHistory && (_vm.session.voteHistory || !_vm.session.isSpectator))?_c('Modal',{staticClass:"vote-history",on:{"close":function($event){return _vm.toggleModal('voteHistory')}}},[(_vm.session.isSpectator)?_c('font-awesome-icon',{staticClass:"clear",attrs:{"icon":"trash-alt","title":"Clear vote history"},on:{"click":_vm.clearVoteHistory}}):_vm._e(),_c('h3',[_vm._v("投票记录")]),(!_vm.session.isSpectator)?[_c('div',{staticClass:"options"},[_c('div',{staticClass:"option",on:{"click":_vm.setRecordVoteHistory}},[_c('font-awesome-icon',{attrs:{"icon":[
            'fas',
            _vm.session.isVoteHistoryAllowed ? 'check-square' : 'square'
          ]}}),_vm._v(" 玩家可查看 ")],1),_c('div',{staticClass:"option",on:{"click":_vm.clearVoteHistory}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}}),_vm._v(" 清除"),(!_vm.session.voteSelected.every(selected => selected === false))?_c('span',[_vm._v("选中")]):_c('span',[_vm._v("全部")]),_vm._v("记录 ")],1)])]:_vm._e(),_c('table',[_c('thead',[_c('tr',[_c('td',[_c('font-awesome-icon',{staticClass:"checkbox",attrs:{"icon":[
              'fas',
              _vm.session.voteSelected.every(selected => selected === true) ? 'check-square' : 'square'
            ]},on:{"click":function($event){return _vm.setVoteSelected(-1)}}})],1),_c('td',[_vm._v("时间")]),_c('td',[_vm._v("提名者")]),_c('td',[_vm._v("被提名者")]),_c('td',[_vm._v("类型")]),_c('td',[_vm._v("模式")]),_c('td',[_vm._v("票数")]),_c('td',[_vm._v("通过票数")]),_c('td',[_c('font-awesome-icon',{attrs:{"icon":"user-friends"}}),_vm._v(" 投票人 ")],1)])]),_c('tbody',_vm._l((_vm.session.voteHistory),function(vote,index){return _c('tr',{key:index},[_c('td',[_c('font-awesome-icon',{staticClass:"checkbox",attrs:{"icon":[
              'fas',
              _vm.session.voteSelected[index] ? 'check-square' : 'square'
            ]},on:{"click":function($event){return _vm.setVoteSelected(index)}}})],1),_c('td',[_vm._v(" "+_vm._s(vote.timestamp .getHours() .toString() .padStart(2, "0"))+":"+_vm._s(vote.timestamp .getMinutes() .toString() .padStart(2, "0"))+" ")]),_c('td',[_vm._v(_vm._s(vote.nominator))]),_c('td',[_vm._v(_vm._s(vote.nominee))]),_c('td',[_vm._v(_vm._s(vote.type))]),_c('td',[_vm._v(_vm._s(vote.mode))]),_c('td',[_vm._v(" "+_vm._s(vote.votes.length)+" "),_c('font-awesome-icon',{attrs:{"icon":"hand-paper"}})],1),_c('td',[_vm._v(" "+_vm._s(vote.majority)+" "),_c('font-awesome-icon',{attrs:{"icon":[
              'fas',
              vote.votes.length >= vote.majority ? 'check-square' : 'square'
            ]}})],1),_c('td',[_vm._v(" "+_vm._s(vote.votes.join(", "))+" ")])])}),0)])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }